<template>
  <v-navigation-drawer
    v-model="open"
    :location="location"
    temporary
    :width="width"
    color="white"
    order="-1"
  >
    <v-img
      v-if="image"
      :src="image"
      gradient="to bottom, rgba(41,96,132,0.2 ), rgba(41,96,132,0.8)"
      cover
      height="230"
      width="600"
    >
      <v-toolbar
        color="transparent"
        flat
      >
        <template #prepend>
          <v-btn
            icon
            data-cy="back_button"
            @click.stop="open = false"
          >
            <v-icon
              v-if="$vuetify.display.xsOnly"
              color="white"
            >
              'mdi-close'
            </v-icon>
            <v-icon
              v-else
              color="white"
            >
              {{ location === 'right' ? 'mdi-chevron-right' : 'mdi-chevron-left' }}
            </v-icon>
          </v-btn>
        </template>

        <template #append>
          <slot name="actions" />
        </template>
      </v-toolbar>

      <v-toolbar
        color="transparent"
        style="margin-top: 102px"
        class="elevation-0"
        flat
      >
        <slot name="headerTitle">
          <v-toolbar-title>
            <div class="text-white text-h4">
              {{ title }}
            </div>
          </v-toolbar-title>
        </slot>
      </v-toolbar>
    </v-img>
    <v-toolbar
      v-if="!image"
      color="transparent"
      elevation="0"
    >
      <template #prepend>
        <v-btn
          icon
          data-cy="back_button"
          @click="open = false"
        >
          <v-icon>{{ location === 'right' ? 'mdi-chevron-right' : 'mdi-chevron-left' }}</v-icon>
        </v-btn>
      </template>

      <slot name="headerTitle">
        <v-toolbar-title
          v-if="title"
          class="text-h4"
          data-cy="sideBarTitle"
        >
          {{ title }}
        </v-toolbar-title>
      </slot>
      <template #append>
        <slot name="actions" />
      </template>
    </v-toolbar>
    <slot />
  </v-navigation-drawer>
</template>
<script>
export default {
  props: {
    modelValue: {
      type: Boolean,
    },
    image: {
      type: String,
      default: null,
    },
    title: {
      type: String,
      default: null,
    },
    width: {
      type: [Number, String],
      default: 600,
    },
    location: {
      type: String,
      default: 'right',
    },
  },
  emits: ['update:modelValue'],
  data() {
    return {}
  },
  computed: {
    open: {
      get() {
        return this.modelValue
      },
      set(newValue) {
        this.$emit('update:modelValue', newValue)
      },
    },
  },
  watch: {
    '$route.path'() {
      this.open = false
    },
  },
  mounted() {},
  methods: {},
}
</script>
